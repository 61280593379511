import React from 'react';
import '../Custom.scss';
import {Link} from "react-router-dom";
import bild from './Content/Startsidabild.png'

function LandingPage() {
    return (
        <>
            <h1>Välkommen till vår bröllopshemsida!</h1>
            <p>
                Här samlar vi, Emilia och Alexander, all information du som inbjuden gäst kan behöva om vårt bröllop,
                för att börja med det viktigaste:
            </p>
            <table>
                <tbody>
                <tr>
                    <td className={"lefttd"}>
                        <b>Datum</b>
                    </td>
                    <td className={"righttd"}>
                        17 juni 2023
                    </td>
                </tr>
                <tr>
                    <td className={"lefttd"}>
                        <b>Tid</b>
                    </td>
                    <td className={"righttd"}>
                        14:00
                    </td>
                </tr>
                <tr>
                    <td className={"lefttd"}>
                        <b>Plats för vigsel</b>
                    </td>
                    <td className={"righttd"}>
                        Björlanda Kyrka
                    </td>
                </tr>
                <tr>
                    <td className={"lefttd"}>
                        <b>Plats för middag</b>
                    </td>
                    <td className={"righttd"}>
                        Björlanda församlingshem
                    </td>
                </tr>
                </tbody>
            </table>
            <br/>
            <p><Link to={"/Svara"}>Klicka här för att OSA senast 10/5</Link></p>
            <p>I menyn högst upp hittar du fler sidor med mer information</p> <br/>
            <img src={bild} width={"300"} title={"Bild på oss"} alt={"Bild på oss"} style={{marginLeft: "auto", marginRight: "auto", display: "block"}}/>
        </>
    );
}

export default LandingPage;
