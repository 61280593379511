import React from 'react';
import '../../Custom.scss';

export default function Svara() {
    return (
        <>
            <h1>OSA</h1>
            <p>
                <b>OSA senast 10/5</b> till <a href={"mailto:emiliaochalexander23@gmail.com"}>emiliaochalexander23@gmail.com</a> <br />
                Ange vilka svaret avser och om någon har allergier eller specialkost som vi behöver känna till. <br />
                <br />
                <b>Välkomna!</b> <br />
                <br />
                Är min respektive inbjuden? <br />
                Svar: Om båda era namn står på inbjudan, alternativt om det står ditt namn "med sällskap" är din respektive inbjuden.
                Ange i svaret om det bara är du eller ni båda som kommer. <br />
                <br />
                Är barnen välkomna? <br />
                Svar: Barn är välkomna men vi önskar att du som är förälder tar med dem ut ur kyrksalen om de blir högljudda under vigseln.
                Du är välkommen att ta kontakt med oss om du har fler funderingar eller frågor kring hur det skulle fungera att ha med barn under kvällen.
                Ange i svaret om barnen följer med.
            </p>
        </>
    );
}
