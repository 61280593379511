import {Link, Outlet} from "react-router-dom";
import {
    CContainer
} from "@coreui/react";
import React, {useState} from "react";
import Navbar from "./Navbar";
import '../Custom.scss'


export default function Root() {
    const [visible, setVisible] = useState(false)

    return (
        <div >
            <Navbar />
            <CContainer>
                <Outlet/>
            </CContainer>
        </div>
    );
}
