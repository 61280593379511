import React from 'react';
import '../../Custom.scss';

export default function Onskelista() {
    return (
        <>
            <h1>Önskelista</h1>
            <p>
                För det första vill vi säga att det absolut inte finns något krav eller förväntan på presenter, <br />
                det viktigaste för oss är att du vill vara med och fira! <br />
                För den som ändå vill ge en bröllopsgåva har vi satt ihop en önskelista som finns hos våra toastmasters. <br />
                Dem når du på följande mailadress: <a href={"mailto:toastmasters.brollop23@gmail.com"}> toastmasters.brollop23@gmail.com</a> <br />
                <br />
                Lika välkommet som ett paket är ett bidrag till bröllopsresan
            </p>
        </>
    );
}
