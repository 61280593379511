import React from 'react';
import '../../Custom.scss';
import forlovning from './Resized.png'

export default function About() {
    return (
        <>
            <h1>Bruden</h1>
            <p>
                <b>Snabbfakta</b> <br />
                Fullständigt namn: Emilia Aida Karin Garpebring <br />
                Ålder: 28 <br />
                Yrke: Dataskyddsjurist <br />
                <br />
                <b>Tre frågor till bruden</b> <br />
                <br />
                Vad är det första du gör när du vaknar på morgonen? <br />
                Snoozar. Sen kanske Alexander får en kram. <br />
                <br />
                Vilken ny hobby skulle du vilja testa? <br />
                Jag har i och för sig redan testat, men jag skulle vilja lära mig koda lite. <br />
                <br />
                Vilket ämne ogillade du mest i skolan? <br />
                Idrott
            </p>

            <h1>Brudgummen</h1>
            <p>
                <b>Snabbfakta</b> <br />
                Fullständigt namn: Jan Lennart Alexander Einerskog <br />
                Ålder: 25 <br />
                Yrke: Utvecklare <br />
                <br />
                <b>Tre frågor till brudgummen</b> <br />
                <br />
                Vad är det första du gör när du vaknar på morgonen? <br />
                På helgen - Gröt. På vardagarna - Cyklar till jobbet där jag äter gröt. <br />
                <br />
                Vilken ny hobby skulle du vilja testa? <br />
                Hade det funnits nåt jag ville testa så hade jag testat det redan. <br />
                <br />
                Vilket ämne ogillade du mest i skolan? <br />
                Bild
            </p>

            <h1>Kort tidslinje</h1>
            <p>
                <b>Början</b> <br />
                <br />
                Emilia och Alexander har känt varandra större delen av livet, och blev vänner under åren i Scouterna. <br />
                <br />
                <b>Nyårsfesten</b> <br />
                <br />
                Under en nyårsfest vid årsskiftet 2019/2020 lade Alexander armen om Emilia, vilket blev startskottet för några månader av pandemi-dejting.
                Paret träffades ofta utomhus i Torslandatrakten och dejtmåltiderna kunde bestå av t ex kalla köttbullar. <br />
                <br />
                <b>Förhållandet</b> <br />
                <br />
                7 juni 2020 gick Emilia äntligen med på att titulera sig "flickvän" och paret började berätta om förhållandet för släkt och vänner. <br />
                <br />
                <b>Förlovningen</b> <br />
                <br />
                Alexander friade till Emilia 14 juli 2022 vid Abiskojåkka i Abisko nationalpark. En förbipasserande norrman hade rekryterats av Alexander för att föreviga ögonblicket.
                <br />
            </p>
            <img src={forlovning} width={"100%"} title={"Förlovade"} alt={"Bild från förlovningen"}/>
            <br />
            <br />
            <br />
        </>
    );
}
