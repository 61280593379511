import React from 'react';
import '../../Custom.scss';

export default function Underhallning() {
    return (
        <>
            <h1>Tal & underhållning</h1>
            <p>
                Våra fantastiska toastmasters, Isak och Josephine, kommer att hålla ihop kvällen.
                <br />
                Om du vill hålla ett tal, eller har förslag på exempelvis en lek, kan du kontakta dem på: <a href={"mailto:toastmasters.brollop23@gmail.com"}> toastmasters.brollop23@gmail.com</a>
            </p>
        </>
    );
}
