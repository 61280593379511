import React from 'react';
import '../../Custom.scss';
import {Link} from "react-router-dom";
import {CContainer} from "@coreui/react";

function Address() {
    return (
        <>
            <h1>Hitta hit</h1>
            <p>
                <b>Kyrkan:</b> Björlanda kyrkväg 30 <br/>
                <b>Församlingshemmet:</b> Nolviksvägen 14
            </p>
            <p><b>Parkering:</b> Parkering finns både vid kyrkan och vid församlingshemmet, och det är bara en kort promenad emellan.</p>
            <p><b>Kollektivtrafik:</b> Om du åker kollektivt heter närmaste busshållplats Björlanda kyrka.
            Tätare turer går dock till Skra Bro, även därifrån är det nära till både kyrka och församlingshem.</p>
        </>
    );
}

export default Address;
