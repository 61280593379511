import React from 'react';
import '../../Custom.scss';

function MatOchDryck() {
    return (
        <>
            <h1>Mat & dryck</h1>
            <p>
                Självklart skall ni inte behöva gå hungriga och törstiga! Det kommer att serveras både mat och dryck. <br/>
                Till maten kommer det att finnas dryck både med och utan alkohol.
            </p>
            <p>Om du har allergier eller specialkost önskar vi att du meddelar detta i samband med att du svarar på inbjudan så att vår
            cateringfirma kan ordna trevliga alternativ till dig.</p>
        </>
    );
}

export default MatOchDryck;
