import React from 'react';
import '../../Custom.scss';

function Kladsel() {
    return (
        <>
            <h1>Klädsel</h1>
            <p>
                <b>Klädkod:</b> Kavaj
            </p>
            <p>... och vad betyder det?</p>
            <p>Välj något du känner dig bekväm i, gärna ljust och somrigt, men som en fingervisning kan man säga: <br/>
                <br />
                <b>För herrar:</b> Hel kostym i valfri färg, slips eller fluga om så önskas. <br />
                <b>För damer:</b> Klänning/kjol (knälång eller vadlång) eller byxdress av det lite finare slaget. <br />
            </p>
        </>
    );
}

export default Kladsel;
