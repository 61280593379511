import React from 'react';
import '../../Custom.scss';

function Foto() {
    return (
        <>
            <h1>Foto</h1>
            <p>
                Vi har anlitat en fotograf, Nadine, som kommer att vara med under dagen. <br/>
                När vi är i kyrkan önskar vi att endast fotografen tar bilder, alla som vill kommer att få ta del av dem när de är klara. <br/>
                Efter vigseln är det fritt fram att fota så mycket man önskar, men ge fotografen utrymme :) <br/>
                Vill ni lägga upp bilder på sociala medier - Använd gärna hashtaggen #einerskogs2023
            </p>
            <p>Vill du inte vara med på bild? Vi kan inte styra detta till 100% med tanke på fotograferande gäster, <br/>
            men vi kan meddela fotografen. <br/>
                Har någon annan publicerat en bild på dig som du inte vill synas på är det enklast att be vederbörande att beskära eller ta ner den.</p>
        </>
    );
}

export default Foto;
