import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from "react-router-dom"
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './Custom.scss'
import Root from "./Pages/Root";
import ErrorPage from "./Pages/Errorpage";
import LandingPage from "./Pages/LandingPage";
import '@coreui/coreui/dist/css/coreui.min.css'
import Address from "./Pages/Content/Address";
import Kladsel from "./Pages/Content/Kladsel";
import Underhallning from "./Pages/Content/Underhallning";
import About from "./Pages/Content/About";
import Svara from "./Pages/Content/Svara";
import Onskelista from "./Pages/Content/Onskelista";
import Foto from "./Pages/Content/Foto";
import MatOchDryck from "./Pages/Content/MatOchDryck";


const router = createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                path: "/",
                element: <LandingPage/>
            },
            {
                path: "/Svara",
                element: <Svara/>
            },
            {
                path: "/Address",
                element: <Address/>
            },
            {
                path: "/Kladsel",
                element: <Kladsel/>
            },
            {
                path: "/Underhallning",
                element: <Underhallning/>
            },
            {
                path: "/Onskelista",
                element: <Onskelista/>
            },
            {
                path: "/MatOchDryck",
                element: <MatOchDryck/>
            },
            {
                path: "/About",
                element: <About/>
            },
            {
                path: "/Foto",
                element: <Foto/>
            },
        ]
    }
])

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
